import Link from 'next/link'
import styled from 'styled-components'

const BreadcrumbContainer = styled.ol`
  list-style: none;
`

const BreadcrumbItem = styled.li`
  display: inline-block;
  margin-left: .5rem;
  color: ${props => props.active ? props.theme.primary.main : "#000000"};
`

const Breadcrumbs = ({ crumbs }) => {
  const isLast = index => index === crumbs.length - 1

  return (
    <BreadcrumbContainer>
      {crumbs.map((item, index) => {
        return <BreadcrumbItem key={item.text} active={isLast(index)}>
          <Link href={isLast(index) ? "#" : item.link}>
            {`${item.text} ${isLast(index) ? "" : '/'}`}
          </Link>
        </BreadcrumbItem>
      })}
    </BreadcrumbContainer>
  )
}

export default Breadcrumbs
