import { 
  Panel,
  ButtonLink, 
  Grid,
  Item,
  Badge
} from '../../styles/global.elements'
import styled from 'styled-components'
import Image from 'next/image'

const ProcedureDescription = styled.div`
  text-align: left;
  font-size: 1rem;
  padding-bottom: 3rem;
`

const ProcedureButton = styled(ButtonLink)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`

const ProcedureItem = ({ title, buttonText, link, image, children, isNew, ...rest }) => {
  return (
    <Item style={{ position: 'relative' }} {...rest}>
      <Panel height="100%" variant="dark-grey" m='.5rem'>
        <h4>{title}</h4>        
        {isNew && (
          <Badge color="teal" style={{ position: 'absolute', top: 0, left: 0 }}>Nuevo</Badge>
        )}
        <Grid breakpoint="sm" stretchOnBreakpoint={true}>
          <Item size="1" width="10rem" mr={["0", "2rem"]} mb="2rem">
            <Image
              src={image}
              alt={title}
              layout="responsive"
              width={500}
              height={500}
            />
          </Item>
          <Item size="2">
            <ProcedureDescription>
              {children}
            </ProcedureDescription>
          </Item>
        </Grid>
        <Item mt="2rem">
          <ProcedureButton href={link}>
            {buttonText}
          </ProcedureButton>
        </Item>
      </Panel>
    </Item>
  )
}
 
export default ProcedureItem
